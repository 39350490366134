import React, { useState, useEffect } from "react";
import "./SummaryFormComponent.css";
import { useSummaryDataAndGeneratePDF } from "../PDFGenerationUtils/useSummaryDataAndGeneratePDF";
import SummaryInput from "./components/SummaryInput";
import CloseSummaryButton from "./components/buttons/CloseSummaryButton";
import useCaptcha from "../../../hooks/useCaptcha";
import { useDispatch, useSelector } from "react-redux";
import FeedbackInput from "./components/FeedbackInput";
import { selectSelectedId as selectSelectedModelId } from "../../../store/reducers/modelChoiceSlice";
import {
  setEmail,
  setFullName,
  setPhone,
  setPostalCode,
  setFeedbackMessage,
  selectEmail,
  selectFullName,
  selectPhone,
  selectPostalCode,
  selectFeedbackMessage,
} from "../../../store/reducers/formInfoSlice";
import {
  validateEmail,
  validatePhone,
  validatePostalCode,
} from "./formValidators";
import emailjs from "@emailjs/browser";
import SendPdfButton from "./components/buttons/SendPdfButton";
import useGenerateOrderNumber from "../PDFGenerationUtils/choiceTranslationUtils/generateOrderNumber";
import EmailSendingIndicatorComponent from "./components/EmailSendingIndicatorComponent";
import getModelTypeText from "../PDFGenerationUtils/choiceTranslationUtils/modelTexts";
import { selectShedOptionsState } from "../../../store/reducers/shedOptionsSlice";
import {
  translateIsGarageChecked,
  translateIsRoomChecked,
  translateSelectedGarageOption,
  translateSelectedRoomOption,
  translateIsShedPositionChecked,
  translateisPhotovoltaicsReinforcementChecked,
  translateShedPosition,
  translateIsSnowZoneChecked,
  translateIsMontageChecked,
  translateIsGrillTypeChecked,
  translateIsWallTypeChecked,
  translateIsGutterTypeChecked,
} from "../PDFGenerationUtils/choiceTranslationUtils/translateShedOptions";
import { selectColorChoices } from "../../../store/reducers/colorChoiceSlice";
import { translateColorCode } from "../PDFGenerationUtils/choiceTranslationUtils/translateColorCode";
import { selectSelectedId as isSelectedPanelColorId } from "../../../store/reducers/panelColorChoiceSlice";
import { translatePanelColorId } from "../PDFGenerationUtils/choiceTranslationUtils/panelColorTranslations";
import { selectSelectedId as selectSelectedPanelId } from "../../../store/reducers/panelChoiceSlice";
import { translatePanelId } from "../PDFGenerationUtils/choiceTranslationUtils/panelIdTranslations";
import { selectSelectedSquares } from "../../../store/reducers/panelPlacementSlice";
import captureV3dContainerScreenshot from "../../../utils/captureV3dContainerScreenshot";
import { selectDimensionsBySelectedId } from "../../../store/reducers/dimensionsSlice";
import PriceCalculator from "../../PriceCalculator/PriceCalculator";
import FinalPriceDisplay from "../../PriceCalculator/FinalPriceDisplay";
import {
  selectAntykondensatCost,
  selectDimensionPrice,
  selectFinalBruttoPrice,
  selectFinalPrice,
  selectGaragePrice,
  selectGutterPrice,
  selectMaskownicaPremiumPrice,
  selectMaskownicaPrice,
  selectPanelPrice,
  selectPanelsPrice,
  selectPhotovoltaicsReinforcementPrice,
  selectPolePrice,
  selectRoomPrice,
} from "../../../store/reducers/finalPriceSlice";
import { calculateSelectedSquares } from "./squaresCalculation";
import { useFormattedDimensions } from "./dimensionsUtils";
import useFirstBusinessEmailRequest from "./useFirstBusinessEmailRequest";

const SummaryFormComponent = ({ onClose }) => {
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const { downloadPDF, getPdfBase64 } = useSummaryDataAndGeneratePDF();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const {
    captchaQuestion,
    userCaptchaInput,
    handleCaptchaInputChange,
    isCaptchaSolved,
    resetCaptcha,
  } = useCaptcha();
  const { sendFirstBusinessEmail } = useFirstBusinessEmailRequest(setIsLoading, resetCaptcha, setAlert);
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const phone = useSelector(selectPhone);
  const generateOrderNumber = useGenerateOrderNumber();
  const orderNumber = generateOrderNumber();
  const postalCode = useSelector(selectPostalCode);
  const feedbackMessage = useSelector(selectFeedbackMessage);
  const isEmailValid = validateEmail(email);
  const isPostalCodeValid = validatePostalCode(postalCode);
  const isPhoneValid = !phone || validatePhone(phone);
  const isButtonEnabled =
    isEmailValid && isPostalCodeValid && isPhoneValid && privacyPolicyChecked && isCaptchaSolved;
    const selectedModelId = useSelector(selectSelectedModelId);
    let modelTypeText = getModelTypeText(selectedModelId);
    const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );

  const {
    formattedWidth,
    formattedLength,
    formattedHeight,
    formattedRoofDimension,
    pgDimensionInformation,
  } = useFormattedDimensions();

  const shedOptions = useSelector(selectShedOptionsState);
  const garageChecked = translateIsGarageChecked(shedOptions.isGarageChecked);
  const garageOption = translateSelectedGarageOption(
    shedOptions.selectedGarageOption
  );
  const shedChecked = translateIsRoomChecked(shedOptions.isRoomChecked);
  const shedOption = translateSelectedRoomOption(
    shedOptions.selectedRoomOption
  );
  const shedPositionChecked = translateIsShedPositionChecked(
    shedOptions.isShedPositionChecked
  );
  const montageChecked = translateIsMontageChecked(
    shedOptions.isMontageChecked
  );
  const shedPosition = translateShedPosition(shedOptions.shedPosition);
  const snowZoneChecked = translateIsSnowZoneChecked(
    shedOptions.isSnowZoneChecked
  );
  const photovoltaicsReinforcementChecked =
    translateisPhotovoltaicsReinforcementChecked(
      shedOptions.isPhotovoltaicsReinforcementChecked
    );
  const maskownicaPremiumChecked = translateIsGrillTypeChecked(
    shedOptions.isGrilleTypeChecked
  );

  const wallTypeChecked = translateIsWallTypeChecked(
    shedOptions.isWallTypeChecked,
    shedOptions.isGarageChecked,
    shedOptions.isRoomChecked
  );

  const rynnaChecked = translateIsGutterTypeChecked(
    shedOptions.isGutterTypeChecked
  );

  const colorChoices = useSelector(selectColorChoices);
  const poleColorName = translateColorCode(colorChoices.poleColor);
  const roofColorName = translateColorCode(colorChoices.roofColor);
  const facadeColorName = translateColorCode(colorChoices.facadeColor);
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);
  const panelColorName = translatePanelColorId(selectedPanelColorId);
  const selectedPanelId = useSelector(selectSelectedPanelId);
  const translatedPanelId = translatePanelId(selectedPanelId);
  const selectedSquares = useSelector(selectSelectedSquares);
  const finalNettoPrice = useSelector(selectFinalPrice);
  const finalBruttoPrice = useSelector(selectFinalBruttoPrice);
  const dimensionPrice = useSelector(selectDimensionPrice);
  const panelPrice = useSelector(selectPanelPrice);
  const panelsPrice = useSelector(selectPanelsPrice);
  const photovoltaicReinforcementPrice = useSelector(
    selectPhotovoltaicsReinforcementPrice
  );
  const antykondensatCost = useSelector(selectAntykondensatCost);
  const roomPrice = useSelector(selectRoomPrice);
  const garagePrice = useSelector(selectGaragePrice);
  const maskownicaPrice = useSelector(selectMaskownicaPrice);
  const maskownicaPremiumPrice = useSelector(selectMaskownicaPremiumPrice);
  const polePrice = useSelector(selectPolePrice);
  const gutterPrice = useSelector(selectGutterPrice);

  let countOfSelectedSquares = calculateSelectedSquares(
    selectedSquares,
    selectedModelId,
    dimensions,
    shedOptions
  );

  const handleInputClick = (event) => {
    event.stopPropagation(); // Only use this if you need to stop the event from bubbling up
  };

  const handlePrivacyCheckbox = (event) => {
    setPrivacyPolicyChecked(event.target.checked);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue =
          "Proces wysyłania Twojej konfiguracji nadal trwa! Czy na pewno chcesz opuścić konfigurator?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  const sendEmailWithPDFAttachment = async () => {
    if (!isEmailValid || !isPostalCodeValid || !privacyPolicyChecked) {
      setAlert({
        show: true,
        message: "Proszę wypełnić wymagane pola!",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
      return;
    }

    if (!isCaptchaSolved) {
      setAlert({
        show: true,
        message: "Proszę poprawnie wypełnić CAPTCHA!",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
      return;
    }

    setIsLoading(true);
    const pdfBase64 = await getPdfBase64();
    const imageDataUrl = await captureV3dContainerScreenshot();
    const imageString = imageDataUrl.split(",")[1];
    await sendFirstBusinessEmail();

    const templateParams3 = {
      screenshotImage: imageString,
      fullName: fullName,
      phone: phone,
      contactEmail: "kontakt@marciniakogrodzenia.pl",
      userEmail: email,
      orderNumber: orderNumber,
      message: "This is a custom message you might want to include.",
      reply_to: email,
      modelType: modelTypeText,
      width: formattedWidth,
      length: formattedLength,
      height: formattedHeight,
      roofDimension: formattedRoofDimension,
      garageChecked: garageChecked,
      garageOption: garageOption,
      shedChecked: shedChecked,
      shedOption: shedOption,
      shedPositionChecked: shedPositionChecked,
      shedPosition: shedPosition,
      montageChecked: montageChecked,
      snowZoneChecked: snowZoneChecked,
      photovoltaicsReinforcementChecked: photovoltaicsReinforcementChecked,
      maskownicaPremiumChecked: maskownicaPremiumChecked,
      wallTypeChecked: wallTypeChecked,
      rynnaChecked: rynnaChecked,
      poleColorName: poleColorName,
      roofColorName: roofColorName,
      facadeColorName: facadeColorName,
      panelColorName: panelColorName,
      translatedPanelId: translatedPanelId,
      countOfSelectedSquares: countOfSelectedSquares,
      feedbackMessage: feedbackMessage,
      postalCode: postalCode,
      finalNettoPrice: finalNettoPrice,
      finalBruttoPrice: finalBruttoPrice,
      dimensionPrice: dimensionPrice,
      panelPrice: panelPrice,
      panelsPrice: panelsPrice,
      photovoltaicReinforcementPrice: photovoltaicReinforcementPrice,
      antykondensatCost: antykondensatCost,
      roomPrice: roomPrice,
      garagePrice: garagePrice,
      maskownicaPrice: maskownicaPrice,
      maskownicaPremiumPrice: maskownicaPremiumPrice,
      polePrice: polePrice,
      gutterPrice: gutterPrice,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_PROJECT,
        templateParams3,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          //console.log('SUCCESS! sent to project', response.status, response.text);
          //setAlert({ show: true, message: 'Konfiguracja została wysłana na Twój email!', type: 'success' });
        },
        (err) => {
          //console.log('FAILED...', err);
          // setAlert({ show: true, message: 'Wystąpił błąd - proszę spróbuj ponownie za kilka minut', type: 'error' });
        }
      )
      .finally(() => {
        setIsLoading(false);
        resetCaptcha();
        setTimeout(
          () => setAlert({ show: false, message: "", type: "" }),
          3000
        );
      });
    //TEN JEST DO UZYTKOWNIKA, CZYLI TUTAJ TEMPLATKA ZOSTAJE
    const templateParams = {
      screenshotImage: imageString,
      fullName: fullName,
      phone: phone,
      contactEmail: email,
      userEmail: email,
      orderNumber: orderNumber,
      message: "This is a custom message you might want to include.",
      reply_to: "konfigurator@marciniakogrodzenia.pl",
      modelType: modelTypeText,
      width: formattedWidth,
      length: formattedLength,
      height: formattedHeight,
      roofDimension: formattedRoofDimension,
      garageChecked: garageChecked,
      garageOption: garageOption,
      shedChecked: shedChecked,
      shedOption: shedOption,
      shedPositionChecked: shedPositionChecked,
      shedPosition: shedPosition,
      montageChecked: montageChecked,
      snowZoneChecked: snowZoneChecked,
      photovoltaicsReinforcementChecked: photovoltaicsReinforcementChecked,
      maskownicaPremiumChecked: maskownicaPremiumChecked,
      wallTypeChecked: wallTypeChecked,
      rynnaChecked: rynnaChecked,
      poleColorName: poleColorName,
      roofColorName: roofColorName,
      facadeColorName: facadeColorName,
      panelColorName: panelColorName,
      translatedPanelId: translatedPanelId,
      countOfSelectedSquares: countOfSelectedSquares,
      feedbackMessage: feedbackMessage,
      postalCode: postalCode,
      pgDimensionInformation: pgDimensionInformation,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_USER,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          //console.log('SUCCESS!', response.status, response.text);
          setAlert({
            show: true,
            message: "Konfiguracja została wysłana na Twój email!",
            type: "success",
          });
        },
        (err) => {
          //console.log('FAILED...', err);
          setAlert({
            show: true,
            message: "Wystąpił błąd - proszę spróbuj ponownie za kilka minut",
            type: "error",
          });
        }
      )
      .finally(() => {
        setIsLoading(false);
        resetCaptcha();
        setTimeout(
          () => setAlert({ show: false, message: "", type: "" }),
           3000
        );
      });
  };

  return (
    <div className="popup-overlay ">
      <div className="popup-content">
        <div className="finalization-summary-container">
          <CloseSummaryButton onClick={onClose} />

          <div className="input-fields">
            <div className="summary-row">
              <SummaryInput
                placeholder="*Adres e-mail"
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                isRequired={true}
                isValid={isEmailValid}
                onClick={handleInputClick}
                icon="Email.png"
              />
              <SummaryInput
                placeholder="Imię i nazwisko (opcjonalne)"
                value={fullName}
                name="to_name"
                onChange={(e) => dispatch(setFullName(e.target.value))}
                onClick={handleInputClick}
                icon="User.png"
              />
            </div>
            <div className="summary-row">
              <SummaryInput
                placeholder="Telefon (opcjonalne)"
                value={phone}
                onChange={(e) => dispatch(setPhone(e.target.value))}
                onClick={handleInputClick}
                icon="Phone.png"
              />
              <SummaryInput
                placeholder="*00-000(Kod pocztowy)"
                value={postalCode}
                onChange={(e) => dispatch(setPostalCode(e.target.value))}
                onClick={handleInputClick}
                isRequired={true}
                isValid={isPostalCodeValid}
                icon="Home.png"
              />
            </div>
            <div className="postal-description">
              Kod pocztowy jest niezbędny do określenia strefy śniegowej
              potrzebnej do dokładnej wyceny.
            </div>
            <div className="cost-description">
              Wycena trwa zwykle do 2-3 godzin
            </div>
            <FeedbackInput
              placeholder="Uwagi klienta"
              value={feedbackMessage}
              onChange={(e) => dispatch(setFeedbackMessage(e.target.value))}
              isRequired={false}
            />
          </div>
          <div className="finalization-container">
            <div className="captcha">
              <div className="captchaquestion">
                <span>{captchaQuestion} = </span>
                <SummaryInput
                  value={userCaptchaInput}
                  onChange={handleCaptchaInputChange}
                  placeholder=" "
                />
              </div>
            </div>

            <div className="buttons-row">
              {/*
             <DownloadPdfButton onClick={downloadPDF} isButtonEnabled={isButtonEnabled} isLoading={isLoading} />
      
            
                             <FinalPriceDisplay/>    
             */}
 <PriceCalculator />
              <SendPdfButton
                onClick={sendEmailWithPDFAttachment}
                isButtonEnabled={isButtonEnabled} // Changed to control the styles
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="privacy-policy">
            <input
              type="checkbox"
              checked={privacyPolicyChecked}
              onChange={handlePrivacyCheckbox}
            />
            <span>
              *Zapoznałam/łem się z{" "}
              <a
                href="https://marciniakogrodzenia.pl/politykaprywatnosci/"
                target="_blank"
                rel="noopener noreferrer"
              >
                polityką prywatności
              </a>
            </span>
          </div>
        </div>
      </div>

      {isLoading && (
        <div>
          <div className="spinner"></div>
          <div className="loading-text">
            Proszę czekać, Twój email jest wysyłany
          </div>
        </div>
      )}
      {alert.show && (
        <EmailSendingIndicatorComponent
          message={alert.message}
          type={alert.type}
        />
      )}
    </div>
  );
};

export default SummaryFormComponent;
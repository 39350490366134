import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedId,
  selectSelectedId,
} from "../../store/reducers/modelChoiceSlice";
import { resetColorChoices } from "../../store/reducers/colorChoiceSlice";
import { resetPanelChoice } from "../../store/reducers/panelChoiceSlice";
import { resetPanelColorChoice } from "../../store/reducers/panelColorChoiceSlice";
import ModelChoiceRhombus from "./ModelChoiceRhombus";
import useInfoWindow from "../../hooks/useInfoWindow";
import "./ModelChoiceComponent.css";
import { toggleGarage, toggleRoom, handleOptionChange, resetOptions } from "../../store/reducers/shedOptionsSlice";
import { selectLastActionOriginModelChoice, setSelectedIdFromOptions } from "../../store/reducers/modelChoiceSlice";
import { selectShedOptionsState } from "../../store/reducers/shedOptionsSlice";

const ModelChoiceComponent = ({ showInfo = true }) => {  // Added showInfo prop with default value true
  const dispatch = useDispatch();
  const selectedId = useSelector(selectSelectedId);
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow();

  const handleSelect = (id) => {
    if (selectedId !== id) {
      // Reset colors and panel colors whenever a different model is selected
      dispatch(resetColorChoices());
      dispatch(resetPanelColorChoice());
      // Reset all other shed options if the selected model is 1.a, 1.b, or 1.c
      if (id === "1.a" || id === "1.b" || id === "1.c") {
        dispatch(resetOptions()); 
        dispatch(resetColorChoices());
        dispatch(resetPanelColorChoice()); // This will reset all relevant shed options
      }
      if (id === "1.d") {
        dispatch(toggleGarage(true)); // Ensure garage is toggled on
        dispatch(handleOptionChange({type: "garage", option: "3.1.a"})); // Select "3.1.a"
      }
    }
    dispatch(setSelectedId(id));
  };
  
  const lastActionOrigin = useSelector(selectLastActionOriginModelChoice);
  const shedOptions = useSelector(selectShedOptionsState);
  const selectedRoomOption = shedOptions.selectedRoomOption;

  useEffect(() => {
    const origin = 'ModelChoiceComponent';
    if (selectedId === "1.e" && lastActionOrigin !== origin) {
      if (selectedRoomOption !== "3.2.a" && selectedRoomOption !== "3.2.c") {
        dispatch(toggleRoom(true, origin));
        dispatch(handleOptionChange({ type: "room", option: "3.2.a", origin }));
      }
    } else if (selectedId === "1.f" && lastActionOrigin !== origin) {
      if (selectedRoomOption !== "3.2.b") {
        dispatch(toggleRoom(true, origin));
        dispatch(handleOptionChange({ type: "room", option: "3.2.b", origin }));
      }
    }
  }, [selectedId, dispatch, lastActionOrigin]);

  useEffect(() => {
    const origin = 'ModelChoiceComponent';
    if (selectedId === "1.e" && lastActionOrigin !== origin && selectedRoomOption === "3.2.a") {
      dispatch(setSelectedIdFromOptions({ id: "1.e", origin }));
    }
  }, [selectedRoomOption, lastActionOrigin, dispatch]);

  return (
    <div className="flex flex-wrap rhombus-container">
      <div className="flex justify-center rhombus-row">
        <div onClick={() => handleSelect("1.a")}>
          <ModelChoiceRhombus
            id="1.a"
            image="Jednostanowiskowa.png"
            hoverText="Jednostanowiskowa"
            selected={selectedId === "1.a"}
            toggleInfoWindow={() => toggleInfoWindow("1.a")}
            showInfo={showInfo}  
          />
        </div>
        <div onClick={() => handleSelect("1.b")}>
          <ModelChoiceRhombus
            id="1.b"
            image="Dwustanowiskowa.png"
            hoverText="Dwustanowiskowa"
            selected={selectedId === "1.b"}
            toggleInfoWindow={() => toggleInfoWindow("1.b")}
            showInfo={showInfo}  
          />
        </div>
        <div onClick={() => handleSelect("1.c")}>
          <ModelChoiceRhombus
            id="1.c"
            image="Wielostanowiskowa.png"
            hoverText="Wielostanowiskowa"
            selected={selectedId === "1.c"}
            toggleInfoWindow={() => toggleInfoWindow("1.c")}
            showInfo={showInfo} 
          />
        </div>
      </div>

      <div className="flex justify-center rhombus-row">
        <div onClick={() => handleSelect("1.d")}>
          <ModelChoiceRhombus
            id="1.d"
            image="wiatazgaraz.webp"
            hoverText="Wiata z garażem"
            selected={selectedId === "1.d"}
            toggleInfoWindow={() => toggleInfoWindow("1.d")}
            showInfo={showInfo}  
          />
        </div>
        <div onClick={() => handleSelect("1.e")}>
          <ModelChoiceRhombus
            id="1.e"
            image="wiatazboku.webp"
            hoverText="Z pomieszczeniem z boku"
            selected={selectedId === "1.e"}
            toggleInfoWindow={() => toggleInfoWindow("1.e")}
            showInfo={showInfo}  
          />
        </div>
        <div onClick={() => handleSelect("1.f")}>
          <ModelChoiceRhombus
            id="1.f"
            image="wiataztylu.webp"
            hoverText="Z pomieszczeniem z tyłu"
            selected={selectedId === "1.f"}
            toggleInfoWindow={() => toggleInfoWindow("1.f")}
            showInfo={showInfo}  
          />
        </div>
      </div>
    
      {showInfo && renderInfoWindow()}  
    </div>
  );
};

export default ModelChoiceComponent;
